import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';
import { getTheme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material';

const AccountActivatedPage = () => {
  const navigate = useNavigate();
  const theme = createTheme(getTheme('purple'));

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  return (
    <ThemeProvider theme={theme}> 
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>JUHU! Dein Account konnte aktiviert werden</h1>
      <p>Jetzt kannst du einfach loslegen.</p>
      <Button variant="contained" onClick={handleNavigateToLogin} sx={{ marginTop: 2 }}>
        Ab zum Login
      </Button>
    </div>
    </ThemeProvider> 
  );
};

export default AccountActivatedPage;
