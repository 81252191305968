import React from 'react';
import { HexColorPicker } from 'react-colorful';

const ColorPicker = ({ color, onChange }) => {
    return (
        <HexColorPicker color={color} onChange={onChange} style={{ width: '94%' }} />
    );
};

export default ColorPicker;

