import React, { useState } from 'react';
import RegistrationForm from '../../features/RegistrationForm/RegistrationForm';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { getTheme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const theme = createTheme(getTheme('purple'));

  const handleBack = () => {
    navigate('/');
  };

  const handleRegistrationSuccess = () => {
    setShowForm(false); // Hide the form
    setTimeout(() => {
      setShowSuccessMessage(true); // Show the success message after a delay
    }, 500); // Adjust the timing as needed
    setTimeout(() => navigate('/login'), 5000); // Redirect after 5 seconds
  };


  const [fieldCompletion, setFieldCompletion] = useState({
    firstName: false,
    lastName: false,
    username: false,
    gender: false,
    birthday: false,
    email: false,
    password: false,
    confirmPassword: false,
    hasAgreedToTerms: false
  });

  return (
    <div className="backgroundContainer">
      <div className={`overlay ${fieldCompletion.lastName ? "revealed" : ""}`} style={{ top: '0%', left: '0%' }}></div>
      <div className= {`overlay ${fieldCompletion.confirmPassword ? "revealed" : ""}`} style={{ top: '0%', left: '33.33%' }}></div>
      <div className={`overlay ${fieldCompletion.password? "revealed" : ""}`} style={{ top: '0%', left: '66.66%' }}></div>
      <div className={`overlay ${fieldCompletion.hasAgreedToTerms ? "revealed" : ""}`} style={{ top: '33.33%', left: '0%' }}></div>
      <div className={`overlay ${fieldCompletion.birthday ? "revealed" : ""}`}  style={{ top: '33.33%', left: '33.33%' }}></div>
      <div className={`overlay ${fieldCompletion.email ? "revealed" : ""}`} style={{ top: '33.33%', left: '66.66%' }}></div>
      <div className={`overlay ${fieldCompletion.username ? "revealed" : ""}`} style={{ top: '66.66%', left: '0%' }}></div>
      <div className={`overlay ${fieldCompletion.firstName ? "revealed" : ""}`} style={{ top: '66.66%', left: '33.33%' }}></div>
      <div className={`overlay ${fieldCompletion.gender ? "revealed" : ""}`}style={{ top: '66.66%', left: '66.66%' }}></div>
      <ThemeProvider theme={theme}> 
      <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center', position: 'relative', zIndex: 2 }}>
        {showForm && (
          <>
            <Typography variant="h4" component="h1" gutterBottom color="white">
              Hier kannst du dein Konto erstellen
            </Typography>
            <Box sx={{
                width: 550,
                backgroundColor: 'rgba(255, 255, 255, 0.97)',
                boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.3)',
                borderRadius: '30px',
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <RegistrationForm setFieldCompletion={setFieldCompletion} onSuccess={handleRegistrationSuccess} />
            </Box>
            <Button variant="contained" sx={{ mt: 2 }} onClick={handleBack}>
              Zurück zur Startseite
            </Button>
          </>
        )}
        {showSuccessMessage && (
          <>
            <Typography variant="h5" color="black"
            sx={{
              mt: 4, // Increased margin-top for more spacing
              fontSize: '6rem', // Larger font size
              textShadow: '0 6px 8px rgba(0,0,0,0.3)', // Subtle text shadow for a lift effect
              animation: 'fadeIn 5s ease-in-out forwards', // Using the fadeIn animation
            }}
            >
              Willkommen bei Thinki
            </Typography>
            <Typography
            variant="h5" // Smaller text for secondary information
            color="black " // Less prominent color
            sx={{
              mt: 2, // Top margin for spacing from the previous message
              animation: 'fadeIn 8s ease-in-out forwards', 
            
            }}
          >
            Bitte bestätige deine Email-Adresse und logge dich nun ein. 
          </Typography>
          </>
        )}
      </Container>
      </ThemeProvider>
    </div>
  );
};

export default RegistrationPage;
