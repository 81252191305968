const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const login = async (username, password) => {
    const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    });
    const data = await response.json();
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
};


export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    // No refresh token available
    return null;
  }

  try {
    const response = await fetch(`${BASE_URL}/refresh`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refresh: refreshToken })
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('accessToken', data.accessToken);
      return data.accessToken;
    } else {
      // Refresh token is invalid or expired
      localStorage.removeItem('refreshToken');
      return null;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};


const unauthorizedEvent = new Event('unauthorized');

export const fetchCourseData = async (courseId) => {
  let accessToken = localStorage.getItem('accessToken');
  const url = `${BASE_URL}/courses/${courseId}`;
  console.log("Fetching data from URL:", url);

  try {
    let response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Try refreshing the token
        accessToken = await refreshToken();
        if (accessToken) {
          // Retry the request with the new access token
          response = await fetch(url, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          });
          if (!response.ok) {
            // If still unauthorized, dispatch the unauthorized event
            window.dispatchEvent(unauthorizedEvent);
            throw new Error('Unauthorized');
          }
        } else {
          // If refresh token is invalid or missing, dispatch the unauthorized event
          window.dispatchEvent(unauthorizedEvent);
          throw new Error('Unauthorized');
        }
      } else {
        // Handle other HTTP errors
        const errorData = await response.json();
        console.error('API Error:', errorData);
        throw new Error(errorData.detail || 'Unknown API error');
      }
    }

    // If response is okay, return the data
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error;
  }
};

  