import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import ENDPOINTS from '../../utils/apiConfig';
import { refreshToken } from '../../utils/AuthenticationService'

const sendFlashcardRating = async (questionId, status) => {
    let accessToken = localStorage.getItem('accessToken');
    const url = ENDPOINTS.CREATE_FLASHCARD_RATING;
  
    const payload = {
      question_id: questionId,
      status: status,
    };
  
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          // Access token might have expired, try refreshing it
          accessToken = await refreshToken();
          response = await fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
        }
        if (!response.ok) {
          const errorData = await response.json();
          console.error('API Error:', errorData);
          throw new Error(errorData.detail || 'Unknown API error');
        }
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error sending flashcard rating:', error);
      throw error;
    }
  };
  
  export const SadSmileyButton = ({ questionId, onStatusUpdate, defaultColor, size=40 }) => {
    const theme = useTheme();
    const [isAnimating, setIsAnimating] = useState(false);
  
    const handleClick = async (event) => {
        setIsAnimating(true);
        try {
          await sendFlashcardRating(questionId, 'sad');
          if (onStatusUpdate) {
            onStatusUpdate('sad');
          }
        } catch (error) {
          console.error('Error sending flashcard rating:', error);
        } finally {
          setTimeout(() => {
            setIsAnimating(false);
          }, 1000);
        }
      };
  
    return (
      <IconButton
        onClick={handleClick}
        sx={{
          width: size,
          height: size,
          padding: 0,
          color: defaultColor || theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }}
      >
        <SadSmiley style={{ width: size, height: size }}
        className={isAnimating ? 'melt-animation' : ''} />
      </IconButton>
    );
  };
  
  
  export const NeutralSmileyButton = ({ questionId, onStatusUpdate, defaultColor, size=40 }) => {
    const theme = useTheme();
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick = async (event) => {
        setIsAnimating(true);
        try {
          await sendFlashcardRating(questionId, 'okay');
          if (onStatusUpdate) {
            onStatusUpdate('okay');
          }
        } catch (error) {
          console.error('Error sending flashcard rating:', error);
        } finally {
          setTimeout(() => {
            setIsAnimating(false);
          }, 1000);
        }
      };
  
    return (
        <IconButton
        onClick={handleClick}
        sx={{
          width: size,
          height: size,
          padding: 0,
          color: defaultColor || theme.palette.primary.main, // Use defaultColor if provided
          '&:hover': {
            color: theme.palette.primary.main, // Change to custom color on hover
          },
        }}
      >
        <NeutralSmiley style={{ width: size, height: size }}
        className={isAnimating ? 'wiggle-animation' : ''} />
      </IconButton>
    );
  };
  
  export const HappySmileyButton = ({ questionId, onStatusUpdate, defaultColor, size = 40, }) => {
    const theme = useTheme();
    const [isAnimating, setIsAnimating] = useState(false);
  
    const handleClick = async (event) => {
        setIsAnimating(true); // Start the animation
        try {
          await sendFlashcardRating(questionId, 'happy');
          if (onStatusUpdate) {
            onStatusUpdate('happy');
          }
        } catch (error) {
          console.error('Error sending flashcard rating:', error);
        } finally {
          // Reset animation after it completes
          setTimeout(() => {
            setIsAnimating(false);
          }, 1000); // Duration should match the animation duration
        }
      };
  
    return (
      <IconButton
        onClick={handleClick}
        sx={{
          width: size,
          height: size,
          padding: 0,
          color: defaultColor || theme.palette.primary.main, 
          '&:hover': {
            color: theme.palette.primary.main, 
          },
        }}
      >
        <HappySmiley style={{ width: size, height: size }}
        className={isAnimating ? 'hop-animation' : ''}/>
      </IconButton>
    );
  };
  