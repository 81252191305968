// src/components/Button/ShuffleButton.js

import React from 'react';
import IconButton from '@mui/material/IconButton';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const ShuffleButton = ({ isShuffled, toggleShuffle }) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={() => {
        console.log("Shuffle button clicked");  // Log when button is clicked
        toggleShuffle();
      }}
      color="inherit" // Control color via sx
      sx={{
        mx: 1, // Adjust as needed
        width: 30, 
        height: 30,
        borderRadius: '50%',
        backgroundColor: 'transparent', // No background
        '&:hover .shuffle-icon': {
          color: isShuffled
            ? theme.palette.primary.dark // Darker primary on hover when active
            : grey[700], // Darker grey on hover when inactive
        },
      }}
    >
      <ShuffleIcon
        className="shuffle-icon"
        sx={{
          fontSize: '20px', // Smaller icon
          color: isShuffled ? theme.palette.primary.main : grey[500], // Primary color when active, grey otherwise
          transition: 'color 0.3s',
        }}
      />
    </IconButton>
  );
};

export default ShuffleButton;

