import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EditFileDialog from '../../features/EditFileDialog/EditFileDialog';
import {useTheme} from '@mui/material';

export default function EditFileButton({ fileId, onFileUpdated }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={handleClickOpen}
                sx={{
                    width: 30,
                    height: 30,
                    padding: 0,
                    color: 'grey', // Default color
                    '&:hover': {
                        color: theme.palette.primary.main, 
                        backgroundColor: 'transparent',
                        '& .MuiSvgIcon-root': { // Targeting the icon itself inside the IconButton
                            animation: 'wiggle 0.2s ease-in-out infinite' // Applying the wiggle animation
                        }
                    },
                    '@keyframes wiggle': { // Defining the keyframes for the wiggle animation
                        '0%, 100%': { transform: 'rotate(-3deg)' },
                        '50%': { transform: 'rotate(3deg)' },
                        
                    }
                }}
            >
                <EditIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {open && <EditFileDialog open={open} onClose={handleClose} fileId={fileId} onFileUpdated={onFileUpdated} />}
        </>
    );
}
