import React, { useState } from 'react';
import Button from '@mui/material/Button';
import LearningMode from '../../features/LearningMode/LearningMode';

const StartLearningModeButton = ({questionSetId, onOpenChange }) => {
  const [open, setOpen] = useState(localStorage.getItem(`learningModeOpen-${questionSetId}`) === 'true');

  const handleClickOpen = () => {
    setOpen(true);
    localStorage.setItem(`learningModeOpen-${questionSetId}`, 'true');
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem(`learningModeOpen-${questionSetId}`);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };
  

  return (
    <>
       <Button
      variant="contained"
      color="secondary"
      size="large"
      onClick={handleClickOpen}
      sx={{
        height: '71px',
        width: "244px",
        fontWeight: 700,  // Use numeric weight for better CSS consistency
        fontSize: '16px',
        boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.25)',
        lineHeight: 1.5,  // Adjusts line height which can control spacing
        display: 'flex',  // Ensures flexbox layout
        flexDirection: 'column',  // Stacks children vertically
        justifyContent: 'center',  // Centers vertically within the button
        alignItems: 'center'  // Centers horizontally
      }}
    >
      <span>Lernmodus</span>
      <span>starten</span>
      </Button>
      <LearningMode open={open} onClose={handleClose} questionSetId={questionSetId} />
    </>
  );
};

export default StartLearningModeButton;
