import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, Typography, Box, Button } from '@mui/material';
import { useThemeContext } from '../../contexts/ThemeContext'
import ColorPicker from './ColorPicker';
import ENDPOINTS from '../../utils/apiConfig';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const ColorMenu = ({ open, handleClose }) => {
    const { toggleTheme, updateCustomColor } = useThemeContext();
    const [color, setColor] = useState('#7F46AB'); 
    const [originalColor, setOriginalColor] = useState('#7F46AB');
    const [isSaved, setIsSaved] = useState(false);


    const handleColorChange = (newColor) => {
        setColor(newColor); // Updates local component state
        updateCustomColor(newColor); // Update the custom color in context
        toggleTheme('individual'); // Switch to the individual theme
    };

    const baseColors = {
        purple: '#7F46AB',
        yellow: '#FFB529',
        blue: '#365DA8',
        green: '#459C52',
        red: '#CD2525',
        orange: '#E36103',
        pink: '#E87AD7',
        black: '#424242',
    };
    

    const saveColorPreference = async (color) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(ENDPOINTS.INTERFACE, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ primary_color: color })
            });
            if (!response.ok) {
                throw new Error('Failed to save the color preference');
            }
            await response.json();
            setIsSaved(true);
            setTimeout(() => setIsSaved(false), 3000); // Reset the save indicator after 3 seconds
        } catch (error) {
            console.error('Error saving color preference:', error.message);
            setIsSaved(false); // Ensure the save status is false on error
        }
    };

    const handleSave = () => {
        saveColorPreference(color).then(() => {
            setIsSaved(true);
            setOriginalColor(color); // Update originalColor after successful save
            setTimeout(() => {
                handleDialogClose(true); // Pass a flag to indicate the color was saved successfully
            }, 2000);
        });
    };
    

    
    useEffect(() => {
        if (open) {
            const fetchOriginalColor = async () => {
                const token = localStorage.getItem('accessToken');
                if (!token) return;
    
                try {
                    const response = await fetch(ENDPOINTS.INTERFACE, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json',
                        },
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        const storedColor = data.primary_color || '#7F46AB';
                        setOriginalColor(storedColor);
                        setColor(storedColor);
                        // Remove the line below to avoid resetting the theme while interacting with the color picker
                        // updateCustomColor(storedColor);
                    } else {
                        console.error('Failed to fetch original color');
                    }
                } catch (error) {
                    console.error('Error fetching color preference:', error);
                }
            };
    
            fetchOriginalColor();
        }
    }, [open]);
    

    const handleDialogClose = (isSaved = false) => {
        if (!isSaved) {
            // If the user did not save, revert to the original color
            setColor(originalColor);
            updateCustomColor(originalColor);
            toggleTheme('individual');
        } else {
            // If saved, make sure the theme matches the saved color
            updateCustomColor(color);
            toggleTheme('individual');
        }
        handleClose();
    };
    

    return (
        <Dialog open={open} onClose={handleDialogClose} BackdropProps={{
            style: {
                backgroundColor: 'transparent', // Makes backdrop transparent
                boxShadow: 'none' // Removes any shadow (if not desired)
            }
                }}
                PaperProps={{
                style: {
                borderRadius: 20, // Matches your color boxes for consistency
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)', // Subtle shadow
                padding: '20px' // Optional padding for aesthetics
            }
        }}
    >
            <DialogTitle sx={{ fontWeight: 'bold' }}>Farbeinstellungen</DialogTitle>
            <Typography variant="h6" sx={{ textAlign: 'left', mt: 2, ml:3 }}>
                Hintergrundfarbe ändern
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1, mt:2}}>
            <Box
                    onClick={() => {
                        toggleTheme('purple');
                        setColor(baseColors['purple']);
                    }}
                    
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #7F46AB 0%, #A178D2 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
                <Box
                    onClick={() => {
                        toggleTheme('yellow');
                        setColor(baseColors['yellow']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #FFB529 0%, #FFD15A 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
                <Box
                    onClick={() => {
                        toggleTheme('blue');
                        setColor(baseColors['blue']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #365DA8 0%, #5B82CD 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
                <Box
                    onClick={() => {
                        toggleTheme('green');
                        setColor(baseColors['green']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #459C52 0%, #6CB878 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1 }}>
            <Box
                    onClick={() => {
                        toggleTheme('red');
                        setColor(baseColors['red']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #CD2525 0%, #F25050 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
                <Box
                    onClick={() => {
                        toggleTheme('orange');
                        setColor(baseColors['orange']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #E36103 0%, #FF8534 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
                <Box
                    onClick={() => {
                        toggleTheme('pink');
                        setColor(baseColors['pink']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #E87AD7 0%, #FFA3F7 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
                <Box
                    onClick={() => {
                        toggleTheme('black');
                        setColor(baseColors['black']);
                    }}
                    sx={{
                        width: 120,
                        height: 120,
                        background: 'linear-gradient( #424242 0%, #757575 100%)',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        margin: 2,
                    }}
                />
            </Box>
            <Typography variant="h6" sx={{ textAlign: 'left', mt: 2, ml:3 }}>
                Wähle eine benutzerdefinierte Farbe:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, mt:2 , flexDirection: 'column', alignItems: 'center'}}>
            <ColorPicker color={color} onChange={handleColorChange} />
            <Button 
                onClick={handleSave} 
                variant="contained" 
                sx={{ mt: 3 }}
                startIcon={isSaved ? <CheckCircleOutlineIcon /> : null} // Show success icon when saved
                disabled={isSaved} // Optional: Disable button when saved
            >
                {isSaved ? 'Gespeichert' : 'Speichern'}
            </Button>
            </Box>
        </Dialog>
    );
};

export default ColorMenu;
