import React from 'react';
import { useState } from "react";
import { validateEmail } from '../../utils/utils';
import "./RegistrationForm.css";
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../utils/apiConfig';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useTheme} from '@mui/material';




const PasswordErrorMessage = () => (
  <p className="FieldError">Das Passwort sollte mindestens 8 Zeichen haben</p>
);

const ConfirmPasswordErrorMessage = () => (
  <p className="FieldError">Die Passwörter stimmen nicht überein</p>
);

const EmailErrorMessage = () => (
    <p className="FieldError">Bitte gib eine gültige Email-Adresse ein</p>
  );

const getTodayMinusYears = (years) => {
    const today = new Date();
    return new Date(today.setFullYear(today.getFullYear() - years)).toISOString().split('T')[0];
  };

  
const RegistrationForm = ({ setFieldCompletion, onSuccess }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState({
    value: "",
    isValid: true,
    isTouched: false,
  });
  const [university, setUniversity] = useState("");
  const [degree, setDegree] = useState("");
  const [major, setMajor] = useState("");
  const [password, setPassword] = useState({
    value: "",
    isTouched: false,
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    isTouched: false,
  });
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [birthdayError, setBirthdayError] = useState('');
  const [passwordError, setPasswordError] = useState([]);
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("")
  const getTodayMinusYears = (years) => {
    const today = new Date();
    return new Date(today.setFullYear(today.getFullYear() - years)).toISOString().split('T')[0];
  
  };

  
  
  const minDate = getTodayMinusYears(100); // today - 100 years
  const maxDate = getTodayMinusYears(16);  // today - 16 years
  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // This prevents the focus behavior on click
  };

  const getIsFormValid = () => {
    return (
      username &&
      email.isValid &&
      validateEmail(email.value) &&
      password.value.length >= 8 &&
      confirmPassword.value === password.value &&
      hasAgreedToTerms &&
      !birthdayError &&
      birthday &&
      gender
    );
  };

  const clearForm = () => {
    setUsername("");
    setGender("");
    setBirthday("");
    setEmail("");
    setPassword({
      value: "",
      isValid: true,
      isTouched: false,
    });
    setConfirmPassword({
      value: "",
      isTouched: false,
    });
    setHasAgreedToTerms(false);
    setPasswordError([]);
    setUsernameError("");
    setEmailError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check for empty mandatory fields before API call
    let hasFormErrors = false;
    
    if (!gender) {
      setGenderError("Bitte Geschlecht auswählen.");
      hasFormErrors = true;
    } else {
      setGenderError("");
    }
    
    if (!birthday) {
      setBirthdayError("Bitte Geburtsdatum eingeben.");
      hasFormErrors = true;
    } else {
      setBirthdayError("");
    }
  
    if (!getIsFormValid() || hasFormErrors) {
      alert("Bitte überprüfen deine Eingaben.");
      return;
    }
  
    const formData = {
      username: username,
      first_name: firstname,
      last_name: lastname,
      email: email.value,
      password: password.value,
      gender: gender,
      birthday: birthday,
      has_agreed_to_terms: hasAgreedToTerms,
      // university: "",  // Assume default or add input field to collect this
      // degree: "",       // Assume default or add input field to collect this
      // major: ""         // Assume default or add input field to collect this
    };
  
    try {
      const response = await fetch(ENDPOINTS.REGISTER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
    
      const data = await response.json();
    
      if (!response.ok) {
        console.error("Error from server:", data);
        setPasswordError([]);
        setUsernameError("");
        setEmailError("");
    
        // Handle specific registration closed error
        if (data.error === "Registration is currently closed.") {
          alert("Die Registrierung ist derzeit geschlossen. Bitte versuche es später erneut.");
          return;
        }
    
        // Handle other server-side validation errors
        if (data.password) {
          const passwordMessages = data.password.map(error => {
            switch (error) {
              case "This password is too common.":
                return "Dieses Passwort wird zu häufig verwendet.";
              case "This password is entirely numeric.":
                return "Das Passwort darf nicht ausschließlich aus Zahlen bestehen.";
              default:
                return "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.";
            }
          });
          setPasswordError(passwordMessages);
        }
    
        if (data.username) {
          const message = data.username[0] === "A user with that username already exists." ? 
                          "Ein Benutzer mit diesem Nutzernamen existiert bereits." : 
                          "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.";
          setUsernameError(message);
        }
    
        if (data.email) {
          const message = data.email[0] === "A user with that email already exists." ? 
                          "Ein Benutzer mit dieser E-Mail-Adresse existiert bereits." : 
                          "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.";
          setEmailError(message);
        }
    
        if (!data.password && !data.username && !data.email) {
          alert(`Ein Fehler ist aufgetreten: ${data.error || "Bitte versuche es erneut."}`);
        }
        return;
      }
    
      alert("Registrierung erfolgreich! Wir haben dir eine Email gesendet, bitte bestätige deine Email-Adresse, um die Registrierung abzuschließen.");
      clearForm();
      onSuccess();
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      alert("A network error occurred. Please try again.");
    }
  }    

return (
  <ThemeProvider  theme={theme} >
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 0,
        
        }}
      >
        <Typography component="h1" variant="h5">
          Registrierung
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                autoComplete="given-name"
                name="firstName"
                fullWidth
                id="firstName"
                label="Vorname"
                value={firstname}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFieldCompletion(prev => ({
                    ...prev,
                    firstName: e.target.value.trim() !== ""  // This will set to true if the field is not empty
                  }));
                }}
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                id="lastName"
                label="Nachname"
                name="lastName"
                autoComplete="family-name"
                value={lastname}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setFieldCompletion(prev => ({
                    ...prev,
                    lastName: e.target.value.trim() !== ""  // This will set to true if the field is not empty
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Nutzername"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setFieldCompletion(prev => ({
                    ...prev,
                    username: e.target.value.trim() !== ""  // This will set to true if the field is not empty
                  }));
                }}
                error={Boolean(usernameError)}
                helperText={usernameError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required error={Boolean(genderError)}>
                <InputLabel id="gender-label">Geschlecht</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  value={gender}
                  label="Geschlecht"
                  onChange={(e) => {
                    setGender(e.target.value);
                    setFieldCompletion(prev => ({
                      ...prev,
                      gender: e.target.value.trim() !== ""  // This will set to true if the field is not empty
                    }));
                  }}
                >
                  <MenuItem value="M">Männlich</MenuItem>
                  <MenuItem value="F">Weiblich</MenuItem>
                  <MenuItem value="O">Divers</MenuItem>
                  <MenuItem value="N">Möchte ich nicht angeben</MenuItem>
                </Select>
                {genderError && <FormHelperText>{genderError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="birthday"
                label="Geburtsdatum"
                type="date"
                defaultValue="2000-01-01"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={birthday}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  let isValidDate = true;
            
                  if (selectedDate > new Date(maxDate)) {
                    setBirthdayError('Du musst mindestens 16 Jahre alt sein, um Thinki zu nutzen. Diese Regelung schützt deine Privatsphäre und entspricht den gesetzlichen Datenschutzvorschriften.');
                    isValidDate = false;
                  } else if (selectedDate < new Date(minDate)) {
                    setBirthdayError('Ungültiges Datum.');
                    isValidDate = false;
                  } else {
                    setBirthdayError('');
                  }
            
                  setBirthday(e.target.value);
                  setFieldCompletion(prev => ({
                    ...prev,
                    birthday: isValidDate && e.target.value.trim() !== "" // This will set to true if the date is valid and not empty
                  }));
                }}
                error={Boolean(birthdayError)}
                helperText={birthdayError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email-Adresse"
                name="email"
                autoComplete="email"
                value={email.value}
                onChange={(e) => {
                  setEmail({
                    ...email,
                    value: e.target.value,
                    isValid: validateEmail(e.target.value),
                  });
                  
                }}
                error={email.isTouched && !email.isValid || Boolean(emailError)}
                helperText={(email.isTouched && !email.isValid && <EmailErrorMessage />) || emailError}
                onBlur={() => {
                setEmail({ ...email, isTouched: true });
                setFieldCompletion(prev => ({
                ...prev,
              email: validateEmail(email.value) // Re-check email validity on blur
            }));
            }}
  />
</Grid>
            {/*
            <Grid item xs={12}>
  <TextField
    
    fullWidth
    id="university"
    label="Universität"
    name="university"
    autoComplete="university"
    value={university}
    onChange={(e) => setUniversity(e.target.value)}
  />
</Grid>

 <Grid item xs={12}>
  <TextField
    
    fullWidth
    id="degree"
    label="Studiengang"
    name="degree"
    autoComplete="degree"
    value={degree}
    onChange={(e) => setDegree(e.target.value)}
  />
</Grid>
<Grid item xs={12}>
  <TextField
    
    fullWidth
    id="major"
    label="Hauptfach"
    name="major"
    autoComplete="major"
    value={major}
    onChange={(e) => setMajor(e.target.value)}
  />
</Grid> */}
            <Grid item xs={12}>
  <TextField
    required
    fullWidth
    name="password"
    label="Passwort"
    type={showPassword ? 'text' : 'password'}
    id="password"
    autoComplete="new-password"
    value={password.value}
    onChange={(e) => {
      setPassword({ ...password, value: e.target.value });
      setFieldCompletion(prev => ({
        ...prev,
        password: e.target.value.length >= 8 // Set to true if password length is valid
      }));
    }}
    onBlur={() => {
      setPassword({ ...password, isTouched: true });
      setFieldCompletion(prev => ({
        ...prev,
        password: password.value.length >= 8 // Confirm validity on blur
      }));
    }}
    error={password.isTouched && password.value.length < 8 || passwordError.length > 0}
    helperText={password.isTouched && password.value.length < 8 ? <PasswordErrorMessage /> : (passwordError.length > 0 && passwordError.join(" "))}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{
              width: 15, 
              height: 15, 
              padding: 0, 
              marginRight: .5, 
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</Grid>

<Grid item xs={12}>
  <TextField
    required
    fullWidth
    name="confirmPassword"
    label="Passwort bestätigen"
    type={showConfirmPassword ? 'text' : 'password'}
    id="confirmPassword"
    autoComplete="new-password"
    value={confirmPassword.value}
    onChange={(e) => {
      setConfirmPassword({ ...confirmPassword, value: e.target.value });
      setFieldCompletion(prev => ({
        ...prev,
        confirmPassword: e.target.value === password.value && e.target.value.length >= 8 // Set to true if match and valid length
      }));
    }}
    onBlur={() => {
      setConfirmPassword({ ...confirmPassword, isTouched: true });
      setFieldCompletion(prev => ({
        ...prev,
        confirmPassword: confirmPassword.value === password.value && confirmPassword.value.length >= 8 // Confirm match and validity on blur
      }));
    }}
    error={confirmPassword.isTouched && confirmPassword.value !== password.value}
    helperText={confirmPassword.isTouched && confirmPassword.value !== password.value && <ConfirmPasswordErrorMessage />}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowConfirmPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{
              width: 15, 
              height: 15, 
              padding: 0, 
              marginRight: .5, 
            }}
          >
            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</Grid>

<Grid item xs={12}>
  <FormControlLabel
    control={
      <Checkbox
        checked={hasAgreedToTerms}
        onChange={(e) => {
          setHasAgreedToTerms(e.target.checked);
          setFieldCompletion(prev => ({
            ...prev,
            hasAgreedToTerms: e.target.checked  // Update the fieldCompletion state based on whether the checkbox is checked
          }));
        }}
        color="success"
      />
    }
    label={
      <React.Fragment>
        Ich habe die {""}
        <Link href="/tos" color="secondary">
          AGBs
        </Link>{" "} gelesen und stimme zu.
      </React.Fragment>
    }
  />
</Grid>

          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!getIsFormValid()}
          >
            Konto erstellen
          </Button>
            <Grid item xs={12}>
              <Link href="/login" variant="body2">
                Bereits registriert? Hier einloggen
              </Link>
            </Grid>
        </Box>
      </Box>
    </Container>
  </ThemeProvider>
);
}

export default RegistrationForm;
