import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshToken as fetchRefreshToken } from '../utils/AuthenticationService';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('access_token'));
  const [loading, setLoading] = useState(true);
  const [logoutMessage, setLogoutMessage] = useState('');
  const navigate = useNavigate();

  const login = (tokens) => {
    localStorage.setItem('access_token', tokens.access);
    localStorage.setItem('refresh_token', tokens.refresh);
    setIsAuthenticated(true);
    setLogoutMessage('');
  };

  const logout = (message = 'You have been logged out. Please log in again.') => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    setLogoutMessage(message);
    // Navigation will be handled in ProtectedRoute after displaying the message
  };

  const checkAuthStatus = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken) {
      setIsAuthenticated(true);
    } else if (refreshToken) {
      try {
        const newAccessToken = await fetchRefreshToken();
        localStorage.setItem('access_token', newAccessToken);
        setIsAuthenticated(true);
      } catch (error) {
        logout('Deine Sitzung ist abgelaufen, bitte logge dich erneut ein.');
      }
    } else {
      logout('Hey, du musst eingeloggt sein, um auf diese Seite zugreifen zu können. Bitte logge dich ein!');
    }

    setLoading(false);
  };

  useEffect(() => {
    checkAuthStatus();

    const handleUnauthorized = () => {
      logout('Your session has expired. Please log in again.');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, loading, logoutMessage, setLogoutMessage }}>
      {children}
    </AuthContext.Provider>
  );
};
