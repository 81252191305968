import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LoginForm from '../../features/LoginForm/LoginForm'; 
import './LoginPage.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useTheme} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { getTheme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material';

const LoginPage = () => {
  const navigate = useNavigate();
  const theme = createTheme(getTheme('purple'));

  const handleBack = () => {
    navigate('/');
  };

  return (
    <ThemeProvider theme={theme}> 
    <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 5 }}>
        Wilkommen bei Thinki!
      </Typography>
      <Box sx={{
          width: 550, // Adjust as necessary
          background: '#FFF',
          boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.3)',
          borderRadius: '30px',
          p: 2, // padding inside the box
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <LoginForm />
      </Box>
      <Button variant="contained"  sx={{ mt: 4, }} onClick={handleBack}>
        Zurück zur Startseite
      </Button>
    </Container>
    </ThemeProvider>
  );
};



export default LoginPage;
