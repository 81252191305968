
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import './LandingPage.css';
import { createTheme } from '@mui/material/styles';
import { getTheme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material';

const LandingPage = () => {
    const navigate = useNavigate();
    const theme = createTheme(getTheme('purple'));
  
    const handleNavigateToRegister = () => {
      navigate('/register');
    };
  
    const handleNavigateToLogin = () => {
      navigate('/login');
    };
  
    return (
      <ThemeProvider theme={theme}> 
      <div className="landing-page">
        <h1>THINKI</h1>
        <p>
          Willkommen bei Thinki! Hier kannst du dich registrieren oder anmelden.
        </p>
        <Button variant="contained" onClick={handleNavigateToRegister} sx={{ margin: 1 }}>Registrieren</Button>
        <Button variant="outlined" onClick={handleNavigateToLogin} sx={{ margin: 1 }}>Anmelden</Button> 
      </div>
      </ThemeProvider>
    );
  };
  
  export default LandingPage;

