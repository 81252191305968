import React from 'react';
import { useState } from "react";
import { useAuth } from '../../contexts/AuthContext'; 
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../utils/apiConfig';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, CircularProgress, Alert, Snackbar } from '@mui/material';
import {useTheme} from '@mui/material';





const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState({ value: "", isTouched: false });
    const [generalError, setGeneralError] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    const isValidEmail = email => {
        return /\S+@\S+\.\S+/.test(email);
      };
    
      const getIsFormValid = () => {
        return isValidEmail(email.value);
      };


      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!getIsFormValid()) {
          setGeneralError("Bitte gib eine gültige Email-Adresse ein.");
          return;
        }
    
        setGeneralError("");
        setLoading(true);
        const resetData = { email: email.value };
    
        try {
          const response = await fetch(ENDPOINTS.RESET, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(resetData),
          });

          const data = await response.json(); 
          setLoading(false);

          if (response.ok) {
            setOpenSnackbar(true);
            setTimeout(() => navigate('/'), 6000); // Redirect after showing the message
          } else {
            if (data.error === "Email not found.") {
                setGeneralError("Email-Adresse nicht gefunden.");
              } else {
                setGeneralError("Ein Fehler ist aufgetreten. Bitte versuche es erneut.");
              }
            }
        } catch (error) {
          setLoading(false);
          console.error("Network error:", error);
          setGeneralError("Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.");
        }
      };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">Passwort vergessen?</Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Emailadresse"
              name="email"
              autoComplete="email"
              autoFocus
              value={email.value}
              onChange={(e) => setEmail({ ...email, value: e.target.value })}
              onBlur={() => setEmail({ ...email, isTouched: true })}
              error={generalError.length > 0}
              helperText={generalError}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
              Neues Passwort anfordern
            </Button>
            <Grid container direction="column" spacing={1}>
              <Grid item xs>
                <Link href="/login" variant="body2">Zurück zum Login</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {loading && <CircularProgress />}
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{
      width: '100%',
      '& .MuiAlert-action': { 
        '.MuiIconButton-root': { 
          marginLeft: 'auto',
          width: '30px', 
          height: '30px', 
          padding: '0px', 
          color: 'inherit', 
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)', 
          }
        }
      }
    }}>
            Wir haben dir eine Email gesendet. Bestätige den Link in der Email, um dein Passwort zurückzusetzen.
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordForm;
