import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar from MUI
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import { Typography } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, logoutMessage, setLogoutMessage } = useAuth();
  const [open, setOpen] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!loading && !isAuthenticated && logoutMessage) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        setShouldRedirect(true);
        setLogoutMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated, loading, logoutMessage, setLogoutMessage]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h9">Thinki is Loading...</Typography>
      </div>
    );
  }

  if (!isAuthenticated && shouldRedirect) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {logoutMessage && (
        <Snackbar
          open={open}
          message={logoutMessage}
          autoHideDuration={3000}
          onClose={() => {
            setOpen(false);
            setShouldRedirect(true);
            setLogoutMessage('');
          }}
        />
      )}
      {isAuthenticated ? children : null}
    </>
  );
};

export default ProtectedRoute;


