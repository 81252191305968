import React from 'react';
import Button from '@mui/material/Button';

export default function CreateQuestionButton({ onClick, sx }) {
    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={onClick}
            sx={{
                mt: 2,
                textTransform: 'none', // Keeps the text as is without uppercase
                ...sx, // Accept additional styles
            }}
        >
            Frage hinzufügen
        </Button>
    );
}
