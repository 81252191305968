import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteQuestionDialog from '../../features/DeleteQuestionDialog/DeleteQuestionDialog';
import {useTheme} from '@mui/material';

export default function DeleteQuestionButton({ questionId, onQuestionDeleted }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="delete"
                onClick={handleClickOpen}
                sx={{
                    width: 5, 
                    height: 5,
                    padding: 0,
                    color: 'grey',
                    '&:hover': {
                        color: theme.palette.primary.main, 
                        backgroundColor: 'transparent',
                        '& .MuiSvgIcon-root': { // Targeting the icon itself inside the IconButton
                            animation: 'bounce 0.6s ease-in-out infinite' // Applying the bounce animation
                        }
                    },
                    '@keyframes bounce': { // Defining the keyframes for the bounce animation
                        '0%, 100%': { transform: 'translateY(0)' },
                        '50%': { transform: 'translateY(-3px)' },
                    }
                }}
            >
                <DeleteIcon style={{ fontSize: '15px' }} />
            </IconButton>
            {open && <DeleteQuestionDialog open={open} onClose={handleClose} questionId={questionId} onQuestionDeleted={onQuestionDeleted} />}
        </>
    );
}
