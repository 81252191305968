import { createTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService';

function adjustColor(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => 
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function getContrastColor(hexColor) {
    if (hexColor.slice(0, 1) === '#') {
        hexColor = hexColor.slice(1);
    }
    let r = parseInt(hexColor.substr(0, 2), 16);
    let g = parseInt(hexColor.substr(2, 2), 16);
    let b = parseInt(hexColor.substr(4, 2), 16);
    const brightness = (r * 0.299 + g * 0.587 + b * 0.114) / 255;
    return brightness > 0.5 ? '#000000' : '#FFFFFF';
}

const fetchColorPreference = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        console.error('No access token available.');
        return '#5B327B';
    }

    const url = ENDPOINTS.INTERFACE; // Ensure this is correctly pointing to your API
    console.log("Fetching color preference from:", url); // Debugging URL

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json' // Make sure to specify that you expect JSON
            }
        });

        if (!response.ok) {
            console.error(`Failed to fetch color preference: HTTP status ${response.status}`);
            const textResponse = await response.text();
            try {
                const errorData = JSON.parse(textResponse);
                console.error('Error details:', errorData);
            } catch {
                console.error('Non-JSON response:', textResponse);
            }
            return '#5B327B'; // Default color if fetch fails
        }

        const data = await response.json();
        console.log('Received color preference:', data); // Debugging response
        return data.primary_color;
    } catch (error) {
        console.error('Error fetching color preference:', error);
        return '#5B327B'; // Default color on error
    }
};



export function getTheme(mode, customColor) {  
    const defaultColor = '#7F46AB';
    const baseColor = {
        purple: '#7F46AB',
        yellow: '#FFB529',
        blue: '#365DA8',
        green: '#459C52',
        red: '#CD2525',
        orange: '#E36103',
        pink: '#E87AD7',
        black: '#424242',
        individual: customColor || '#000000',
    }[mode] || defaultColor;  // Default to purple if mode is undefined
    const lighten = (color, amount) => adjustColor(color, amount);
    const darken = (color, amount) => adjustColor(color, -amount);
    const colors = {
        primary: { main: baseColor, contrastText: getContrastColor(baseColor) },
        secondary: { main: baseColor, contrastText: getContrastColor(baseColor) },
        gradientBackground: `linear-gradient( ${lighten(baseColor, 60)} 0%, ${baseColor} 100%)`,
        sidebarTypography: getContrastColor(baseColor),
        iconColor: getContrastColor(baseColor),
    };

    return createTheme({
        typography: {
            fontFamily: 'Nunito, sans-serif',
            h1: {
                lineHeight: 1.5,
                letterSpacing: '0.009em',
            },
        },
        palette: {
            primary: colors.primary,
            secondary: colors.secondary,
            gradient: {
                background: colors.gradientBackground,
            },
            success: {
                main: '#4caf50',
                contrastText: '#fff',
            },
            error: {
                main: '#f44336',
                contrastText: '#fff',
            },
            icon: {
                main: colors.iconColor,
            }
        },
        components: {
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: colors.sidebarTypography,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 'bold',
                        borderRadius: '30px',
                    },
                    containedPrimary: {
                        '&:hover': {
                            backgroundColor: colors.secondary.main,
                        },
                    },
                },
            },
        },
    });
}

export const useCustomTheme = () => {
    const [customColor, setCustomColor] = useState('#5B327B'); // Default to purple

    useEffect(() => {
        fetchColorPreference().then(color => {
            setCustomColor(color || '#5B327B');
        });
    }, []);

    const theme = getTheme('individual', customColor);

    return theme;
};
