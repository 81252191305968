import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteCourseDialog from '../../features/DeleteCourseDialog/DeleteCourseDialog';
import {useTheme} from '@mui/material';

export default function EditCourseButton({ courseId, onCourseUpdated }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={handleClickOpen}
                sx={{
                    width: 30, 
                    height: 30,
                padding: 0,
                    color: 'grey', // Default color
                    '&:hover': {
                        color: theme.palette.primary.main, 
                        backgroundColor: 'transparent',
                        '& .MuiSvgIcon-root': { // Targeting the icon itself inside the IconButton
                            animation: 'bounce 0.6s ease-in-out infinite' // Applying the bounce animation
                        }
                    },
                    '@keyframes bounce': { // Defining the keyframes for the bounce animation
                        '0%, 100%': { transform: 'translateY(0)' },
                        '50%': { transform: 'translateY(-3px)' },
                    }
                }}
            >
                <DeleteIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {open && <DeleteCourseDialog open={open} onClose={handleClose} courseId={courseId} onCourseUpdated={onCourseUpdated} />}
        </>
    );
}
