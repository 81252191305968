import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Paper,
  IconButton, 
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'; // Import PushPinOutlinedIcon
import PushPinIcon from '@mui/icons-material/PushPin'; // Import PushPinIcon
import { grey } from '@mui/material/colors';

const MenuContainer = styled(Paper)(({ theme, isPinned }) => ({
  width: '267px',
  height: isPinned ? '230px' : '58px', // Adjust height based on pinned state
  borderRadius: '15px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  // Only apply hover effect if not pinned
  '&:hover': isPinned
    ? {}
    : {
        height: '230px',
      },
}));

const CollapsedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ExpandedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

const StatisticsMenu = ({ questions, pinnedStateKey }) => {
    const theme = useTheme();
  
    // Initialize isPinned from localStorage
    const [isPinned, setIsPinned] = useState(() => {
      const savedPinnedState = localStorage.getItem(pinnedStateKey);
      return savedPinnedState ? JSON.parse(savedPinnedState) : false;
    });
  
    // Function to toggle pinned state and persist it
    const togglePinned = () => {
      setIsPinned((prev) => {
        const newState = !prev;
        localStorage.setItem(pinnedStateKey, JSON.stringify(newState));
        return newState;
      });
    };
  
    // Calculate the counts for each status
    const [statusCounts, setStatusCounts] = useState({
      sad: 0,
      okay: 0,
      happy: 0,
      none: 0,
    });
  
    useEffect(() => {
      // Calculate counts
      const counts = questions.reduce((acc, question) => {
        const status = question.flashcard_status || 'none';
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, { sad: 0, okay: 0, happy: 0, none: 0 });
  
      setStatusCounts(counts);
    }, [questions]);
  
    // Rotation state
    const [rotations, setRotations] = useState({
      sad: [],
      okay: [],
      happy: [],
      none: [],
    });
  
    // Ref to store previous statusCounts
    const prevStatusCounts = useRef(statusCounts);
  
    // Initialize rotations on mount
    useEffect(() => {
      setRotations({
        sad: Array.from({ length: statusCounts.sad }, () => (Math.random() - 0.5) * 10),
        okay: Array.from({ length: statusCounts.okay }, () => (Math.random() - 0.5) * 10),
        happy: Array.from({ length: statusCounts.happy }, () => (Math.random() - 0.5) * 10),
        none: Array.from({ length: statusCounts.none }, () => (Math.random() - 0.5) * 10),
      });
      prevStatusCounts.current = statusCounts;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run once on mount
  
    // Update rotations when statusCounts change
    useEffect(() => {
      ['sad', 'okay', 'happy', 'none'].forEach((status) => {
        const prevCount = prevStatusCounts.current[status] || 0;
        const currentCount = statusCounts[status] || 0;
  
        if (currentCount > prevCount) {
          const numToAdd = currentCount - prevCount;
          setRotations((prevRot) => ({
            ...prevRot,
            [status]: [
              ...prevRot[status],
              ...Array.from({ length: numToAdd }, () => (Math.random() - 0.5) * 10),
            ],
          }));
        } else if (currentCount < prevCount) {
          const numToRemove = prevCount - currentCount;
          setRotations((prevRot) => ({
            ...prevRot,
            [status]: prevRot[status].slice(0, currentCount),
          }));
        }
      });
  
      // Update previous counts
      prevStatusCounts.current = statusCounts;
    }, [statusCounts]);

    const renderStack = (rotationsArray) => {
        const maxStackHeight = 80; // Maximum height of the stack
        const lineHeight = 1.5; // Base height of each line
        const lineWidth = 20; // Width of each line
        const lineSpacing = 2; // Spacing between lines
      
        const count = rotationsArray.length;
      
        // Calculate the total stack height
        let stackHeight = count * (lineHeight + lineSpacing);
      
        // Adjust line height if stackHeight exceeds maxStackHeight
        let adjustedLineHeight = lineHeight;
        if (stackHeight > maxStackHeight) {
          adjustedLineHeight = (maxStackHeight - count * lineSpacing) / count;
          stackHeight = maxStackHeight;
        }
      
        // Generate the lines using the stored rotations
        const lines = rotationsArray.map((rotation, index) => (
          <Box
            key={index}
            sx={{
              width: `${lineWidth}px`,
              height: `${adjustedLineHeight}px`,
              backgroundColor: theme.palette.primary.main,
              marginBottom: `${lineSpacing}px`,
              transform: `rotate(${rotation}deg)`,
            }}
          />
        ));
      
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end', // Align lines to the bottom
              height: `${maxStackHeight}px`,
              overflow: 'hidden',
              marginBottom: '5px',
            }}
          >
            {lines}
          </Box>
        );
      };
      

      return (
        <MenuContainer elevation={3} isPinned={isPinned}>
          {/* Collapsed Content with Statistics Icon */}
          <CollapsedContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
                mt: 2,
              }}
            >
              <BarChartIcon
                style={{
                  width: 22,
                  height: 22,
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
          </CollapsedContent>
          {/* Expanded Content */}
          <ExpandedContent>
            {/* Pin Icon at Top-Right Corner */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end', // Aligns the icon to the right
                mb: 1, // Adds some spacing below the pin icon
              }}
            >
              <IconButton
                onClick={togglePinned}
                sx={{
                  color: "#d1d1d1",
                  height: 24, // Adjust as needed
                  width: 24,  // Adjust as needed
                }}
                aria-label={isPinned ? 'Unpin menu' : 'Pin menu'} // Accessibility
              >
                {isPinned ? (
                  <PushPinIcon sx={{ fontSize: 18 }} />
                ) : (
                  <PushPinOutlinedIcon sx={{ fontSize: 18 }} />
                )}
              </IconButton>
            </Box>
      
            {/* Display stacks */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexGrow: 1, // Allow the container to grow and align items properly
              }}
            >
              {/* Sad */}
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end', // Align content to bottom
                }}
              >
                {renderStack(rotations.sad)}
                <SadSmiley
                  style={{
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
              {/* Neutral */}
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end', // Align content to bottom
                }}
              >
                {renderStack(rotations.okay)}
                <NeutralSmiley
                  style={{
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
              {/* Happy */}
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end', // Align content to bottom
                }}
              >
                {renderStack(rotations.happy)}
                <HappySmiley
                  style={{
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
              {/* None */}
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end', // Align content to bottom
                }}
              >
                {renderStack(rotations.none)}
                <HelpOutlineIcon
                  style={{
                    width: 22,
                    height: 22,
                    color: theme.palette.primary.main,
                  }}
                />
              </Box>
            </Box>
          </ExpandedContent>
        </MenuContainer>
      );
      
};

export default StatisticsMenu;


