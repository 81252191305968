import { useEffect } from 'react';
import { fetchQuestionCreationRequestById } from '../services/QuestionCreationRequestService';

const usePollQuizzes = (creationRequestId, onUpdate, setQuizError) => {
  useEffect(() => {
    if (!creationRequestId) return;

    let intervalId;

    const pollQuiz = async () => {
      try {
        console.log('Polling creation request ID:', creationRequestId);
        const creationRequestData = await fetchQuestionCreationRequestById(creationRequestId);
        console.log('Creation request data:', creationRequestData);

        onUpdate(creationRequestData);

        if (creationRequestData.status !== 'pending' && creationRequestData.status !== 'in_progress') {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error polling creation request:', error);
        setQuizError('Error fetching creation request details');
        clearInterval(intervalId);
      }
    };

    pollQuiz();
    intervalId = setInterval(pollQuiz, 10000);

    return () => clearInterval(intervalId);
  }, [creationRequestId, onUpdate, setQuizError]);
};

export default usePollQuizzes;


